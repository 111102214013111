import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import DownloadCard from '../components/downloadCard'
import Col from 'react-bootstrap/Col'
import image from '../assets/img/intro.jpg'




const DownloadsPage = () => {
  const data = useStaticQuery(downloadQuery)

  return (
    <>
      <Layout title={'Downloads'} subtitle={'HRBC'}>
        <section id="downloads" className="page-section cta">
          <Container>
            <Row>
              <Col xl={9} className="mx-auto">
                <div className="cta-inner text-center rounded">
                  <h2 className="section-heading mb-5">
                    <span className="section-heading-upper">All</span>
                    <span className="section-heading-lower">Downloads</span>
                  </h2>
                  {data.allStrapiDownload.nodes.map((data, index) => (
                    <DownloadCard 
                      key={index} 
                      name={data.name} 
                      downloadName={data.content.name} 
                      updatedAt={data.updated_at} 
                      size={data.content.size} 
                      ext={data.content.ext} 
                      publicURL={data.content.publicURL} 
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default DownloadsPage



const downloadQuery = graphql`{
  allStrapiDownload {
    nodes {
      name
      description
      content {
        publicURL
        ext
        name
        size
      }
      updated_at    
    }
  }
}
`

const stylesheet = {

}